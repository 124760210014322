import 'datatables.net-rowgroup';

import { FormatDate } from '../../../../shared/scripts/date-functions';

import { IMilestone } from './interfaces';
import { MilestoneGanttChart } from './gantt-chart';
import { MilestoneDataTable } from './data-table';

export class MilestonesPage {

    ganttChart: MilestoneGanttChart;
    dataTable: MilestoneDataTable;

    constructor() {

        $(() => {
            //console.log("Milestones Page Loaded");

            this.initializePage();

            this.ganttChart = new MilestoneGanttChart();
            this.dataTable = new MilestoneDataTable();
        });

    }

    initializePage(): void {

        $("#RequestedReportId").on("change", (event) => {
            var reportId = $(event.currentTarget).val();

            $('#loading-indicator').removeClass('d-none').addClass('d-block');
            $("#report-name").empty();
            $('#last-updated-section').removeClass('d-block').addClass('d-none');
            $("#gantt-chart").empty();
            $('#gantt-legend').removeClass('d-inline-block').addClass('d-none');
            this.dataTable.hideTable();

            if (reportId) {

                var url = '/secure/reports/milestones/milestones/MilestoneReport';

                $.get(url, { reportId: reportId }, (report) => {
                    //console.log(report);

                    var lastUpdateDate: Date = new Date(report.lastUpdateDate);

                    $('#loading-indicator').removeClass('d-block').addClass('d-none');
                    $('#report-name').html(report.name);
                    $('#last-update-date').html(FormatDate(lastUpdateDate));
                    $('#last-updated-section').removeClass('d-none').addClass('d-block');
                    $('#gantt-legend').removeClass('d-none').addClass('d-inline-block');

                    var milestones: IMilestone[] = <IMilestone[]>JSON.parse(report.milestonesJSON);

                    //add timezeone offset to milestone dates as defaults to UTC which adjusts based on local timezone
                    milestones = milestones.map(m => {
                        if (m.ActualStartDate) m.ActualStartDate += ' 00:00:00';
                        if (m.DeliveredDate) m.DeliveredDate += ' 00:00:00';
                        if (m.DueDate) m.DueDate += ' 00:00:00';
                        if (m.StartDate) m.StartDate += ' 00:00:00';
                        return m;
                    });

                    //console.log(milestones);

                    this.ganttChart.initialize(milestones);
                    this.dataTable.initialize(milestones);
                });
            }
            else { //no report selected
                $('#loading-indicator').removeClass('d-block').addClass('d-none');
                $('#report-name').html('<span class="d-inline default-messsage">Select a study from the drop-down menu.</span>');
            }


        });
    }
}


