import { plainToClass } from 'class-transformer';
import { DocumentTree } from '../../shared/components/document-tree/document-tree.objects';

export class DocumentPage {

    documentTree: DocumentTree;

    constructor(nodes: any, dialogId: string) {
        $(() => {

            //console.log("Init");
            console.log(nodes);
            this.documentTree = new DocumentTree(nodes);

            //if (dialogId != "") {
            //    $("#" + dialogId).modal({
            //        backdrop: 'static',
            //        keyboard: false
            //    });
            //    $("#" + dialogId).modal('show');
            //}

        });
    }
}
