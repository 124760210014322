import { FormatDate } from '../../../../shared/scripts/date-functions';
import { IMilestone } from './interfaces';

export class MilestoneDataTable {

    dataTable: any;

    constructor() { }

    initialize(milestones: IMilestone[]) {

        //console.log(milestones);

        $('#milestones-table-container').removeClass('d-none').addClass('d-block');

        if (this.dataTable == null) {
            this.dataTable = $('#milestones-report-table').DataTable({
                retrieve: true,
                autoWidth: false,
                //"order": [[1, "asc"]],
                info: false,
                paging: false,
                scrollX: true,
                searching: false,
                rowGroup: {
                    dataSrc: 'Category'
                },
                columnDefs: [
                    {
                        targets: [1],
                        visible: false,
                        searchable: false
                    }],
                columns: [
                    { data: "SortOrder" },
                    { data: "Category" },
                    { data: "Description", width: '18%' },
                    { data: "CompletionCriteria", width: '18%' },
                    { data: "MilestoneType" },
                    {
                        data: "PercentComplete",
                        className: 'dt-center',
                        render: function (data, type, row, meta) {
                            let output: string;
                            if (type !== 'display') { output = data; }
                            else {
                                let percent: number = Math.round(data * 100.0);
                                let statusClass: string = "incomplete";
                                if (percent == 100) {
                                    statusClass = "complete";
                                }
                                output = '<div class="progress position-relative ' + statusClass + '"><div class="progress-bar" style="width:' + percent + '%"></div><div class="label justify-content-center d-flex position-absolute w-100">' + percent + '%</div></div>'

                            }
                            return output;
                        }
                    },
                    { data: "DueDate", width: '75px', render: function (data, type, row, meta) { return (data != null) ? FormatDate(data) : ""; } },
                    { data: "DeliveredDate", width: '75px', render: function (data, type, row, meta) { return  (data != null) ? FormatDate(data) : ""; } },
                    { data: "Comments", width: '18%' }
                ]
            });
        }

        this.dataTable.clear();
        this.dataTable.rows.add(milestones);
        this.dataTable.draw();
    }

    hideTable() {
        $('#milestones-table-container').removeClass('d-block').addClass('d-none');
    }

}