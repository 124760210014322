
//var dt = require('datatables.net')(window, $);


// SCRIPTS

//to make discoverable from cshtml
import { DocumentPage } from './documents/documents'; export { DocumentPage };
import { DirectoryPage } from './communications/directory/directory.page'; export { DirectoryPage };
import { MilestonesPage } from './reports/milestones/scripts/milestones.page'; export { MilestonesPage };


// STYLES
import './communications/directory/directory.scss';

//import 'jstree/dist/themes/default/style.min.css';
//import './home/home.scss';
import './reports/milestones/milestones.scss';
//import './reports/sub-otas/sub-otas.scss';
//import './reports/enrollment/enrollment.scss';
//import './reports/geomap/geomap.scss';
//import './studies/study-list/study_list.scss';
//import './pasc/cohort-builder/cohort-builder.scss';
